// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  @include on-event {
    color: darken($color-tertiary, 75%);
    text-decoration: underline;
  }

  color: $color-secondary;
  text-decoration: none;
}

/**
 * Background colors and basic setups
 */
html,
body {
  background-color: $color-tertiary;
  height: 100%;
}

#root {
  background-color: $color-tertiary;
  margin: 0;
}

code {
  font-family: $code-font-stack;
}

/**
 * Main frame of the page beneath navbar
 */
.main {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2rem;
  width: 100%;
}

/**
 * Makes a button look like a regular href inline link
 */
.inline-link {
  display: inline;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: baseline;
}

/**
 * Makes an icon inline (inside text)
 */
.inline-icon {
  align-items: center;
  display: inline-flex;
  margin: 0;
  padding: 0;
}

/**
 * Renders new lines properly
 */
textarea {
  white-space: pre-line;
}

/**
 * Renders new lines properly
 */
.pre-like {
  white-space: pre-line;
}

/**
 * Centers the icon within the button component
 */
.center-button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
}

/**
 * Margin to make icons appear on the same level as text
 */
.octicon {
  margin-bottom: 3px;
  margin-left: 2px;
}

/**
 * Splash screen to display when loading
 */
.splash-screen {
  align-items: center;
  display: flex;
  height: 80vh;
  justify-content: center;
  margin: auto;
}

@charset 'UTF-8';

// 1. Configuration and helpers
@import
'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

// 2. Vendors
@import
'vendors/normalize',
'vendors/bootstrap',
'vendors/react-widgets';

// 3. Base stuff
@import
'base/base',
'base/fonts',
'base/typography',
'base/helpers';

// 4. Layout-related sections
@import
'layout/header',
'layout/footer';

// 5. Components
@import
'components/sidebar',
'components/filters',
'components/toggle',
'components/splash-screen',
'components/sidebar',
'components/box',
'components/divider',
'components/navbar',
'components/footer';

// 6. Page-specific styles
@import
'pages/home',
'pages/meeting',
'pages/login';

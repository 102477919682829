// -----------------------------------------------------------------------------
// This file contains styles that are specific to the login page.
// -----------------------------------------------------------------------------

.login-background {
  align-items: center;
  display: flex;
  height: 100vh;
  padding-bottom: 80px;
}

.login-container {
  margin: auto;
  max-width: 300px;
  padding: 15px;
  width: 100%;
}

.border-left {
  border-left: 2px;
}

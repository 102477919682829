// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Bootstrap package.
// We modify the color schemes and individual elements supplied by Bootstrap.
// -----------------------------------------------------------------------------

$theme-colors: (
  'primary': $color-secondary,
  'secondary': $color-tertiary,
  'info': $color-quaternary,
);

.navbar {
  background-color: $color-primary;
  min-height: 56px;
}

%btn-link-secondary {
  color: $color-tertiary;
  font-weight: 400;
  text-decoration: none;
}

.nav-link {
  border-color: #dee2e6 #dee2e6 #dee2e6;
}

.tabs-heading {
  color: $color-quinary;
}

.dropdown-menu {
  &.show {
    left: auto;
    right: -15px;
  }
}

.nav-link-icon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  top: 0;
}

.card-header {
  background-color: $color-secondary;
  color: $text-color;
}

.card {
  background-color: none;
}

.list-group-item {
  background-color: $white;
}

@import '~bootstrap/scss/bootstrap';

/**
 * Basic typography style for copy text
 */

/**
 * Sets text color and basic font
 */
body {
  color: $text-color;
  font-family: $text-font-stack;
  font-weight: 400;
}

/**
 * Footer on the bottom of the home page
 */
 
footer {
  background-color: darken($color-tertiary, 3%);
  font-size: 12px;
  height: 40px;
  margin-left: -30px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100vw;
}

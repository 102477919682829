/**
 * Horizontal line, dividing paragraphs
 */
.divider {
  border-color: rgba($mid-grey, .7);
  border-style: inset;
  border-width: .5px;
  margin: auto;
  margin-bottom: 1em;
  margin-top: 1em;
}
